import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import Login from '../../pages/log-in/login';

const ModalComponent = (props) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {props.buttonLabel}
      </Button>
      <Modal
        title={props.title}
        open={open}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer=""
      >
        {props.component}
      </Modal>
    </>
  );
};

export default ModalComponent;