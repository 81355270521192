import React from 'react';
import { Button, Checkbox, Form, Input, Modal } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { userLoginUrl } from '../../api/api';




const Login = () => {

  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState('Please Wait...');

  const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setOpen(false);
        if (modalText === 'Login Success'){
          window.location.reload(true);
        }
    };
    const handleCancel = () => {
        setOpen(false);
    };

  const onFinish = (values) => {
    const { username, password } = values;
    axios.post(userLoginUrl, { username, password }).then(res => {
      // console.log(res.data.errno);
      if (res.data.errno === 0) {
        setModalText('Login Success');
      } else {
        setModalText('Login Failed');
      }
    })
    // console.log('Success:', values);
  };
  
  const onFinishFailed = (errorInfo) => {
    setModalText('Login failed');
    // console.log('Failed:', errorInfo);
  };
  
  return (
  <Form
    name="basic"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    style={{ maxWidth: 600 }}
    initialValues={{ remember: true }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Username"
      name="username"
      rules={[{ required: true, message: 'Please input your username!' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Password"
      name="password"
      rules={[{ required: true, message: 'Please input your password!' }]}
    >
      <Input.Password />
    </Form.Item>

    <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
      <Checkbox>Remember me</Checkbox>
    </Form.Item>

    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
      <Button type="primary" htmlType="submit" onClick={showModal} >
        Submit
      </Button>
      <Modal 
                title="Submit Result"
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>{modalText}</p>
      </Modal>
    </Form.Item>
  </Form>
)};

export default Login;